



















































import { SystemTypeWrapper } from '@/lib-on-fhir';
import Vue from 'vue';

export default Vue.extend({
    props: {
        element: {
            type: SystemTypeWrapper,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        patternValue(): string {
            return this.element.fixedValue || this.element.pattern;
        },

        validationClass(): string {
            if (this.patternValue) {
                return this.element.value === this.patternValue ? 'good' : 'bad';
            }
            return 'neutral';
        },
    },

    methods: {
        deleteElement() {
            this.element.deleteValue();
        },
    },
});
